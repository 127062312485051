<template>
  <mobile-screen
    :header="true"
    screen-class="search-filter-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="search-header icon-hea1">
        <template v-slot:left>
          <button @click="closeFilters">
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          {{ displayLabelName("search.presets.presets") }}
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_bookings-search-preset-add' }">
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul
      v-if="presets && presets.length"
      class="clebex-item-section overflow-hidden"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("search.filters.custom-presets") }}
            </label>
          </div>
        </div>
      </li>
      <Draggable
        v-bind="dragOptions"
        @end="orderPresets"
        :list="activePresets"
        item-key="id"
        tag="li"
        class="clebex-item-section-item no-bottom-border light no-separator"
      >
        <template #item="{element}">
          <div
            class="clebex-item-content-wrapper cursor-pointer"
            @click="activatePreset(element.id)"
          >
            <span
              @click.stop="deletePresetFn(element.id)"
              class="follow-up-icons delete-preset search-preset"
            >
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="14"
                    height="14"
                    style="width: 14px; height: 14px;"
                  >
                    <use xlink:href="#cx-app1-delete-02"></use>
                  </svg>
                </div>
              </span>
            </span>
            <span
              @click.stop="
                goToPresetPage({
                  name: 'r_bookings-search-preset-edit',
                  params: { id: element.id }
                })
              "
              class="follow-up-icons edit-preset search-preset"
            >
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="14"
                    height="14"
                    style="width: 14px; height: 14px;"
                  >
                    <use xlink:href="#cx-hea1-edit"></use>
                  </svg>
                </div>
              </span>
            </span>
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer">{{
                element.name
              }}</label>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="12"
                    style="width: 12px; height: 12px;"
                  >
                    <use xlink:href="#cx-app1-checkmark"></use>
                  </svg>
                </div>
              </span>
            </span>
          </div>
        </template>
      </Draggable>
      <li
        v-for="element in presets.filter(el => !el.is_active)"
        :key="element.id"
        class="clebex-item-section-item no-bottom-border light no-separator"
      >
        <div
          class="clebex-item-content-wrapper cursor-pointer"
          @click="activatePreset(element.id)"
        >
          <span
            @click.stop="deletePresetFn(element.id)"
            class="follow-up-icons delete-preset search-preset"
          >
            <span class="follow-up-icon-item">
              <div class="svg-icon">
                <svg width="14" height="14" style="width: 14px; height: 14px;">
                  <use xlink:href="#cx-app1-delete-02"></use>
                </svg>
              </div>
            </span>
          </span>
          <span
            @click.stop="
              goToPresetPage({
                name: 'r_bookings-search-preset-edit',
                params: { id: element.id }
              })
            "
            class="follow-up-icons edit-preset search-preset"
          >
            <span class="follow-up-icon-item">
              <div class="svg-icon">
                <svg width="14" height="14" style="width: 14px; height: 14px;">
                  <use xlink:href="#cx-hea1-edit"></use>
                </svg>
              </div>
            </span>
          </span>
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer">{{
              element.name
            }}</label>
          </div>
        </div>
      </li>
    </ul>

    <template v-slot:footer> </template>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal"
      type="success"
      :confirm-action="deletePresetModalFn"
      :confirm-button-label="
        displayLabelName('search', 'filters', 'delete-confirm-action')
      "
      :cancelButtonLabel="
        displayLabelName('search', 'filters', 'delete-cancel-action')
      "
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("search", "filters", "delete-preset") }}
      </h3>
      <p class="text">
        {{ displayLabelName("search", "filters", "delete-preset-text") }}
        <br />
        {{ displayLabelName("search", "filters", "delete-preset-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "SearchPresets",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Draggable: defineAsyncComponent(() => import("vuedraggable"))
  },
  data() {
    return {
      collapse: {},
      dragOptions: {
        animation: 150,
        delay: 1000,
        delayOnTouchOnly: true
      },
      showDeleteModal: false,
      presetToDelete: null
    };
  },
  computed: {
    ...mapState("search", ["presets", "activePresets"])
  },
  methods: {
    ...mapActions("search", ["activatePreset", "deletePreset", "orderPresets"]),
    closeFilters() {
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    },
    deletePresetFn(id) {
      this.presetToDelete = id;
      this.showDeleteModal = true;
    },
    deletePresetModalFn() {
      this.deletePreset(this.presetToDelete);
    },
    goToPresetPage(route) {
      this.$router.push(route);
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    }
  }
};
</script>
